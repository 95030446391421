// Vendors
import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';

// Actions
import { openModal, toggleModal } from '../../../reducers/modal/modalActions';

// Components
import { PaymentCard, PaymentCardListAddButton } from '..';
import {
  Trans,
  Dropdown,
  DeletePaymentAccountModal,
  AddPaymentAccountModal,
  EditPaymentAccountModal,
} from '../../common';
import { ButtonIcon } from 'texkit/dist/components/Buttons';

// Helpers
import { isDateExpired } from '../../../lib/helpers/util';

const toggleComponent = ({ ...props }) => (
  <ButtonIcon className="payment-list-item-icon" icon="more" {...props} />
);

const PaymentCardList = ({ paymentAccounts }) => {
  const dispatch = useDispatch();

  const onEdit = paymentAccount => {
    dispatch(toggleModal('EditPaymentAccountModal', { paymentAccount }));
  };

  const onRemove = paymentAccount => {
    dispatch(toggleModal('DeletePaymentAccountModal', { paymentAccount }));
  };

  const CardList = paymentAccounts.map((paymentAccount, index) => {
    const dropdownItems = [
      {
        label: <Trans file="Labels" id="Edit" fallback="Edit" />,
        onClick: () => onEdit(paymentAccount),
      },
      {
        label: <Trans file="Labels" id="Remove" fallback="Remove" />,
        onClick: () => onRemove(paymentAccount),
      },
    ];
    const isCardExpired = isDateExpired(paymentAccount.expirationDate);

    return (
      <div className="payment-list-item" key={`paymentCard${index}`}>
        <PaymentCard
          paymentAccount={paymentAccount}
          isCardExpired={isCardExpired}
        />
        <Dropdown
          className="payment-list-item-more-blue-svg"
          id={`payment-dropdown-${index}`}
          toggleComponent={toggleComponent}
          items={dropdownItems}
        />
      </div>
    );
  });

  return (
    <div className="payment-list">
      {CardList}
      <PaymentCardListAddButton
        onClick={() => dispatch(openModal('AddPaymentAccountModal'))}
        content={
          <Trans file="Payment" id="AddNewCard" fallback="Add new card" />
        }
      />

      <DeletePaymentAccountModal />
      <AddPaymentAccountModal />
      <EditPaymentAccountModal />
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ toggleModal }, dispatch);

PaymentCardList.propTypes = {
  paymentAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      paymentAccountType: PropTypes.string.isRequired,
      cardNumber: PropTypes.string.isRequired,
      expirationDate: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default connect(null, mapDispatchToProps)(PaymentCardList);
