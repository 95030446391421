export const paymentAccountsData = {
  Checking: { id: 0, icon: '', name: 'Checking Account' },
  Saving: { id: 1, icon: '', name: 'Savings Account' },
  CreditDiscover: { id: 2, icon: 'discover', name: 'Discover' },
  CreditVisa: { id: 3, icon: 'visa', name: 'Visa' },
  CreditAmericanExpress: { id: 4, icon: 'amex', name: 'AmericanExpress' },
  CreditMasterCard: { id: 5, icon: 'mastercard', name: 'Mastercard' },
  DebitDiscover: { id: 6, icon: 'discover', name: 'Discover' },
  DebitVisa: { id: 7, icon: 'visa', name: 'Visa Debit' },
  DebitAmericanExpress: { id: 8, icon: 'amex', name: 'AmericanExpress Debit' },
  DebitMasterCard: { id: 9, icon: 'mastercard', name: 'Mastercard Debit' },
  DualDiscover: { id: 10, icon: 'discover', name: 'Discover Dual' },
  DualVisa: { id: 11, icon: 'visa', name: 'Visa Dual' },
  DualAmericanExpress: { id: 12, icon: 'amex', name: 'American Express Dual' },
  VISA: { id: 13, icon: 'visa', name: 'Visa' },
  AMEX: { id: 14, icon: 'amex', name: 'AmericanExpress' },
  DISC: { id: 15, icon: 'discover', name: 'Discover' },
  MC: { id: 16, icon: 'mastercard', name: 'Mastercard' },
};
