// Vendors
import React, { useMemo } from 'react';

import { TxAlert } from 'texkit-ui/components';
import { formikInjectedPropsTypes } from '../../../lib/validation/propTypes/formikPropTypes';

import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

// Components
import { Trans, InputRadioGroup, EditPaymentAccountModal } from '../..';
import { LoadingDots } from '../../common';
import { AddPaymentCard, PaymentCard } from '..';

// Selectors
import { selectPaymentAccounts } from '../../../reducers/payment/paymentSelectors';
import { selectPathname } from '../../../reducers/route/routeSelectors';

// Actions
import { push } from 'connected-react-router';
import { getPaymentRoute } from '../../../lib/helpers';
import { openModal } from '../../../reducers/modal/modalActions';

// Helpers
import { isDateExpired } from '../../../lib/helpers/util';

const PaymentSelectCardList = ({ formikProps }) => {
  const dispatch = useDispatch();
  const currentPath = useSelector(selectPathname);
  const paymentAccounts = useSelector(selectPaymentAccounts);
  const setFieldValue = formikProps.setFieldValue;
  let showWarning = false;

  if (!paymentAccounts) {
    return <LoadingDots />;
  }

  const inputOptions = useMemo(() => {
    const inputOptions = paymentAccounts.map((paymentAccount, index) => {
      let isCardSelected =
        formikProps.values.selectPayment &&
        formikProps.values.selectPayment.paymentAccountId ===
          paymentAccount.paymentAccountId;

      if (
        formikProps.status &&
        formikProps.status.serverErrors &&
        formikProps.status.serverErrors.formError
      ) {
        if (formikProps.status.serverErrors.formError == 'MGMW_EX_3112') {
          showWarning = true;
        }
      }

      const onEdit = () =>
        dispatch(openModal('EditPaymentAccountModal', { paymentAccount }));

      const isCardExpired = isDateExpired(paymentAccount.expirationDate);
      return {
        className: classNames('payment-select-list-item', {
          'selected-payment': isCardSelected,
          disabled: isCardExpired,
        }),
        id: `selectPayment${index}`,
        value: paymentAccount,
        disabled: isCardExpired,
        label: (
          <PaymentCard
            paymentAccount={paymentAccount}
            formikProps={formikProps}
            showCvv={
              (currentPath.includes('dmvrr') ||
                currentPath.includes('dpslr') ||
                currentPath.includes('tdlrlr')) &&
              isCardSelected
            }
            showWarning={showWarning}
            showEditButton={true}
            onEdit={onEdit}
            isCardExpired={isCardExpired}
          />
        ),
      };
    });

    inputOptions.push({
      className: classNames('payment-select-list-item', {
        'selected-payment':
          formikProps.values.selectPayment.paymentAccountId === 'addcard',
      }),
      id: `selectPayment${inputOptions.length}`,
      value: {
        paymentAccountId: 'addcard',
      },
      label: (
        <AddPaymentCard
          showForm={
            formikProps.values.selectPayment.paymentAccountId === 'addcard'
          }
        />
      ),
    });

    return inputOptions;
  }, [formikProps.values.selectPayment.paymentAccountId]);

  const handleUseDifferentCardClick = () =>
    dispatch(push(getPaymentRoute('/form', currentPath)));

  const onChangeCallback = () => {
    if (setFieldValue) {
      if (formikProps.values.cvv) {
        setFieldValue('cvv', '');
      }
    }
  };

  return (
    <>
      <div className="payment-select">
        <div className="payment-select-list">
          {inputOptions && inputOptions.length > 0 && (
            <InputRadioGroup
              name="selectPayment"
              optionValueKey="paymentAccountId"
              formikProps={formikProps}
              options={inputOptions}
              onChange={onChangeCallback}
            />
          )}
        </div>

        {showWarning && (
          <div>
            <br />
            <TxAlert
              type="warning"
              heading={<Trans file="Errors" id="PaymentFailure_Title" />}
            >
              <Trans
                file="Errors"
                id={
                  currentPath.includes('dmvrr')
                    ? 'dmvrr_PaymentFailure_Desc'
                    : 'PaymentFailure_Desc'
                }
              />
            </TxAlert>
          </div>
        )}
      </div>
      <EditPaymentAccountModal />
    </>
  );
};

PaymentSelectCardList.propTypes = {
  formikProps: formikInjectedPropsTypes.isRequired,
};

export default PaymentSelectCardList;
