//@flow

// Vendors
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Modal, Trans } from '../..';
import { TxButton, TxButtonMode } from 'texkit-ui';
import { TxPaymentCardForm, TxPaymentCardFormMode } from 'texkit-ui/forms';

// Actions
import {
  getPaymentAccount,
  getPaymentAccounts,
} from '../../../../reducers/payment/paymentActions';
import { openModal, closeModal } from '../../../../reducers/modal/modalActions';

// Selectors
import { selectModalData } from '../../../../reducers/modal/modalSelectors';
import { selectAppLanguage } from '../../../../reducers/app/appSelectors';

// Helpers
import { formatExpirationDate } from '../../../../lib/helpers';

import './edit-payment-account-modal.scss';

const EditPaymentAccountModal = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState();
  const { paymentAccount } = useSelector(selectModalData);
  const selectedLanguage = useSelector(selectAppLanguage);

  const fetchPaymentAccount = async paymentAccount => {
    try {
      setInitialValues();
      const { paymentAccountId } = paymentAccount;
      const { status, data } = await dispatch(
        getPaymentAccount(paymentAccountId)
      );

      if (status === 'ERROR') {
        dispatch(openModal('ServerErrorModal'));
        setInitialValues();
        return;
      }

      const { cardLast4, expirationDate } = data;
      const address = data.paymentRequest.address || {};
      const accountInfo = {
        cardNumber: cardLast4,
        expirationDate: formatExpirationDate(expirationDate),
        name: data.paymentRequest.paymentAccountName || '',
        streetAddress: address.addressLine1,
        streetAddress2: address.addressLine2,
        city: address.city,
        state: address.stateCd,
        zip: address.zipCode,
      };

      setInitialValues(accountInfo);
      setIsLoading(false);
    } catch (error) {
      console.error('fetchPaymentAccount error', error);
    }
  };

  const handleSuccess = () => {
    dispatch(getPaymentAccounts());
    dismissModal();
  };

  const handleOnSubmit = values => {
    console.log('handleOnSubmit', values);
    dismissModal();
  };

  const dismissModal = () => {
    setInitialValues();
    dispatch(closeModal('EditPaymentAccountModal'));
  };

  return (
    <Modal
      name="EditPaymentAccountModal"
      className="edit-payment-account-modal"
      disableOnClickOutside="true"
      onClose={dismissModal}
      onOpen={() => fetchPaymentAccount(paymentAccount)}
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Payment"
          id="UpdateCardInformation"
          fallback="Update card information"
        />
      </h2>
      <TxPaymentCardForm
        acceptedCards={['VISA', 'MasterCard', 'Discover', 'American Express']}
        billingAddressTitle={<Trans file="Payment" id="BillingAddressTitle" />}
        iFrameUrl="about:blank"
        mode={TxPaymentCardFormMode.Edit}
        onSubmit={handleOnSubmit}
        onTokenReceived={() => {}}
        paymentMethodTitle={<Trans file="Payment" id="CardInformationTitle" />}
        spanish={selectedLanguage === 'es'}
        initialValues={initialValues}
        renderFormActions={(isValid, isSubmitting) => (
          <TxButton
            mode={TxButtonMode.Primary}
            type="submit"
            disabled={!isValid || isSubmitting || isLoading}
          >
            <Trans file="Payment" id="SavePaymentMethod" />
          </TxButton>
        )}
      />
    </Modal>
  );
};

export default EditPaymentAccountModal;
